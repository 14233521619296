import React, { ReactElement, useEffect, useState } from 'react'
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Upload,
  UploadFile,
  UploadProps
} from "antd";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import ImgCrop from 'antd-img-crop';
import * as faceapi from 'face-api.js';
import { uploadBytes, getDownloadURL } from "firebase/storage";
// @ts-ignore
import uuid from "uuid-v4";
import { checkRequest, saveData, saveId, startLoading, stopLoading } from "../verify.actions";
import { useDispatch, useSelector } from "react-redux";
import { useReduxDispatch } from "../../../helpers";
import { saveDataParams } from "../verify.types";
import { getLoading } from "../verify.selectors";
import { ref, storage } from "../../../firebase";
import { RcFile, UploadChangeParam } from "antd/es/upload";
import { openNotification } from "../../../utils/notifications";
import moment from "moment";
import Header from "../../../components/Header";

const { Option } = Select;

export default function UploadContainer(): ReactElement {
  const dispatch = useReduxDispatch()
  const navigate = useNavigate()
  const isLoading = useSelector(getLoading)
  const [frontUrl, setFrontUrl] = useState('')
  const [doctype, setDoctype] = useState('')
  const [imageLoading, setImageLoading] = useState(false);
  const [modelsLoaded, setModelsLoaded] = React.useState(false);
  const [requestClosed, setRequestClosed] = useState(false);
  const [form] = Form.useForm();

  useEffect(()=>{
    const requestId = window.location.search.split('requestid=')[1]
    //@ts-ignore
    dispatch(checkRequest(requestId)).then((data: any)=>{
      if(data.requestClosed){
        setRequestClosed(true)
        openNotification('error', 'Invalid Request', 'This link is invalid or expired.')
      }
      else {
        loadModels();
      }
    })
  },[])

  const loadModels = async () => {
    const MODEL_URL = process.env.PUBLIC_URL + '/weights';
    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
      faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
      faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
      faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
    ]).then(() => setModelsLoaded(true));
  }

  const uploadProps = {
    beforeUpload: (file:any) => {
      console.log(file.type)
      const isPNG = file.type.includes('image/');
      if (!isPNG) {
        message.error(`${file.name} is not a image file`);
      }
      return isPNG || Upload.LIST_IGNORE;
    },
    name: 'file',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info: any) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    // @ts-ignore
    customRequest({ onError, onSuccess, file, filename, data }) {
      console.log('data', data)
      const token = uuid()
      const metadata = {
        contentType: 'image/jpeg',
        metadata: {
          firebaseStorageDownloadTokens: token, // Can technically be anything you want
        },
      }
      const storageRef = ref(storage, `${token}.jpg`);
      try {
        uploadBytes(storageRef, file, metadata).then((snapshot) => {
          getDownloadURL(storageRef)
            .then((url) => {
              console.log('file', file)

              onSuccess(null, snapshot);
            })
            .catch((error) => {
              // Uh-oh, an error occurred!
            });
        });
      } catch (e) {
        onError(e);
      }
    }
  };

  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setImageLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, url => {
        setImageLoading(false);
        setFrontUrl(url)
        form.setFieldsValue({'document':url})
      });
    }
  };

  const onFinish = async (values: any) => {
    dispatch(startLoading())
    const requestId = window.location.search.split('requestid=')[1]
    if (!requestId) {
      openNotification('error', 'Invalid Request', 'This link is invalid or expired.')
      return
    }
    const imageInput = document.getElementById("inputImg")
    //@ts-ignore
    const results = await faceapi.detectAllFaces(imageInput, new faceapi.TinyFaceDetectorOptions({
      inputSize: 512,
      scoreThreshold: 0.6
    })).withFaceLandmarks().withFaceDescriptors()
    if (!results || results.length === 0) {
      dispatch(stopLoading())
      openNotification('error', 'Wrong Document', 'This document is not readable/acceptable. Please upload a different document.')
      return
    }

    const box = results[0].detection.box
    const regionsToExtract = [new faceapi.Rect(box.x - 40, box.y -40 , box.width + 80, box.height + 80)]

    //@ts-ignore
    let faceImages = await faceapi.extractFaces(imageInput, regionsToExtract)
    const userImageBase64 = await faceImages[0].toDataURL()

    const idData = {
      firstname: values.firstname,
      lastname: values.lastname,
      middlename: values.middlename ? values.middlename : '',
      address: values.address,
      birthday: moment(values.birthday),
      issue: moment(values.issue),
      expiry: moment(values.expiry),
      type: values.types
    }
    const data = {
      id: requestId,
      userIdImage: frontUrl,
      userImage: userImageBase64,
      idData
    }
    const result = await dispatch(saveId(data))
    if (result !== null) {
      navigate(`/face_detection?requestid=${requestId}`)
    }
  }
  const uploadButton = (
    <div>
      {imageLoading ? <LoadingOutlined/> : <PlusOutlined/>}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  // @ts-ignore
  return (
    <div className={'app-container p-20'} style={{marginTop:0}}>
      <Header/>
      <Form
        form={form}
        hideRequiredMark
        labelCol={{ span: 6 }}
        onFinish={onFinish}
      >
        <Row align={'middle'} justify={'center'} className={"mt-10"}>
          <Col xs={24}>
            <Card title="Upload you Identity Document">
              <Row justify={'space-between'}>
                <Col xs={24} md={9}>
                  <Row>
                    <Col xs={24}>
                      <label htmlFor="types">Document Type</label>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        name={'types'}
                        rules={[{ required: true, message: 'Please select document type' }]}
                      >
                        <Select
                          onChange={(value)=>{setDoctype(value)}}
                          placeholder={'Please select document type'} style={{ display: 'block' }}>
                          <Option value="licence">Driver License</Option>
                          <Option value="idDocument">Government ID</Option>
                          <Option value="passport">Passport</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <label htmlFor="types">Upload Document</label>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        name={'document'}
                        rules={[{ required: true, message: 'Please select document' }]}
                      >
                        {
                          <ImgCrop
                            showReset
                            aspectSlider
                            aspect={16 / 9}
                            quality={0.7}
                            minZoom={0.5}
                            rotationSlider>
                            {
                              //@ts-ignore
                              <Upload
                                {...uploadProps}
                                name="document"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                maxCount={1}
                                onChange={handleChange}
                              >
                                {frontUrl ? <img id={'inputImg'} src={frontUrl} alt="avatar"
                                                 style={{ height: '100%' }}/> : uploadButton}
                              </Upload>
                            }
                          </ImgCrop>
                        }
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={14}>
                  <Col xs={24}>
                    <Form.Item
                      rules={[{ required: true, message: 'Please enter first name' }]}
                      label="First Name"
                      name="firstname">
                      <Input/>
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      label="Middle Name"
                      name="middlename">
                      <Input/>
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      rules={[{ required: true, message: 'Please enter last name' }]}
                      label="Last Name"
                      name="lastname">
                      <Input/>
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      rules={[{ required: true, message: 'Please select your birthday' }]}
                      label="Birthday" name="birthday">
                      <DatePicker
                        placeholder={'yyyy-mm-dd'}
                        style={{ display: 'block' }}/>
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Row>
                      <Col xs={24} md={12}>
                        <Form.Item
                          rules={[{ required: doctype !== 'licence', message: 'Please select document issue date' }]}
                          labelCol={{ span: 12 }}
                          label="Issue Date"
                          name="issue">
                          <DatePicker
                            placeholder={'yyyy-mm-dd'}
                            disabled={doctype === 'licence'} style={{ display: 'block' }}/>
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item
                          labelCol={{ span: 12 }}
                          rules={[{ required: true, message: 'Please select document expiry date' }]}
                          label="Expiry Date" name="expiry">
                          <DatePicker
                            placeholder={'yyyy-mm-dd'}
                            style={{ display: 'block' }}/>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      rules={[{ required: true, message: 'Please enter complete address' }]}
                      label="Address" name="address">
                      <Input/>
                    </Form.Item>
                  </Col>
                </Col>
              </Row>
              <Row align={'middle'} justify={'center'} className={'mt-40'}>
                <Col xs={24} md={18}>
                  <Button
                    disabled={requestClosed}
                    htmlType={'submit'}
                    block
                    className={'accept-button'}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
