import React from "react";
import { Col } from "antd";

function Header() {
  return (
    <Col xs={24}>
      <div style={{ width: '100%', height: 50 }}>
        <img src={'https://idenfication.com/wp-content/uploads/2023/08/idenfication.png'} style={{height: 35}}/>
      </div>
    </Col>
  )
}

export default Header
