import IAction from "../../interfaces/IAction";
import * as CONSTANTS from "./verify.constants";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import axios from "axios";
import { ResponseData, saveDataParams, saveIdParams, saveImageParams } from "./verify.types";
import {
  getCheckRequestUrl,
  getImageDataUrl,
  getProcessRequestUrl,
  getSaveDataUrl,
  getSaveIdUrl
} from "../../constants/urls";
import { message } from "antd";

export const startLoading = (): IAction => ({
  type: CONSTANTS.START_LOADING,
})
export const stopLoading = (): IAction => ({
  type: CONSTANTS.STOP_LOADING,
})
export const saveData = (data: saveDataParams): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<ResponseData | null> => {
  return new Promise(async resolve => {
    try {
      dispatch(startLoading())
      const response = await axios.post<ResponseData>(getSaveDataUrl(), data, {
        headers: {}
      })
      dispatch(stopLoading())
      if(response.status === 200 && response.data)
        resolve(response.data)
      else {
        message.error(response.data.error)
        resolve(null)
      }
    } catch (error: any) {
      dispatch(stopLoading())
      message.error(error?.toString())
      resolve(null)
    }
  })
}

export const checkRequest = (id: string): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<ResponseData | null> => {
  return new Promise(async resolve => {
    try {
      dispatch(startLoading())
      const response = await axios.get<ResponseData>(getCheckRequestUrl(id), {
        headers: {}
      })
      dispatch(stopLoading())
      if(response.status === 202 && response.data)
        resolve(response.data)
      else {
        message.error(response.data.error)
        resolve(null)
      }
    } catch (error: any) {
      dispatch(stopLoading())
      message.error(error?.toString())
      resolve(null)
    }
  })
}

export const processRequest = (id: string): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<ResponseData | null> => {
  return new Promise(async resolve => {
    try {
      //dispatch(startLoading())
      const response = await axios.get<ResponseData>(getProcessRequestUrl(id), {
        headers: {}
      })
      dispatch(stopLoading())
      if(response.status === 202 && response.data)
        resolve(response.data)
      else {
        message.error(response.data.error)
        resolve(null)
      }
    } catch (error: any) {
      dispatch(stopLoading())
      message.error(error?.toString())
      resolve(null)
    }
  })
}

export const saveExpressionData = (data: saveImageParams): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<ResponseData | null> => {
  return new Promise(async resolve => {
    try {
      dispatch(startLoading())
      const response = await axios.post<ResponseData>(getImageDataUrl(), data, {
        headers: {}
      })
      dispatch(stopLoading())
      if(response.status === 202 && response.data)
        resolve(response.data)
      else {
        message.error(response.data.error)
        resolve(null)
      }
    } catch (error: any) {
      dispatch(stopLoading())
      message.error(error?.toString())
      resolve(null)
    }
  })
}

export const saveId = (data: saveIdParams): ThunkAction<void, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<ResponseData | null> => {
  return new Promise(async resolve => {
    try {
      dispatch(startLoading())
      const response = await axios.post<ResponseData>(getSaveIdUrl(), data, {
        headers: {}
      })
      dispatch(stopLoading())
      if(response.status === 202 && response.data)
        resolve(response.data)
      else {
        message.error(response.data.error)
        resolve(null)
      }
    } catch (error: any) {
      dispatch(stopLoading())
      message.error(error?.toString())
      resolve(null)
    }
  })
}
