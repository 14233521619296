import React, { useEffect, useState } from 'react'
import { useReduxDispatch } from 'src/helpers'
import getIsLoggedIn from 'src/helpers/getIsLoggedIn'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getCurrentUser } from 'src/modules/core/core.selectors'
import { PATHS } from '../../../constants'

interface Props {
  children: React.ReactNode
}

const MainLayout = ({ children }: Props) => {
  const dispatch = useReduxDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const user = useSelector(getCurrentUser)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [path, setPath] = useState<string | null>(null)

  useEffect(() => {
    //dispatch(getUser())

    if (location.pathname !== PATHS.ROOT) {
      setPath(location.pathname)
    }
  }, [])

  useEffect(() => {
    if (!user.id) return
    //dispatch(fetchCurrency())
  }, [user])
  useEffect(() => {
    if (!path) return
    navigate(path)
    setPath(null)
  }, [])

  const fetchUser = async () => {
    const isLoggedIn = await getIsLoggedIn()

    setIsAuthenticated(isLoggedIn)
  }

  useEffect(() => {
    fetchUser()
  })

  const renderLayout = (children: React.ReactNode) => {
    if (!isAuthenticated) {
      return children
    }

    return children
  }

  return <>{renderLayout(children)}</>
}

export default MainLayout
