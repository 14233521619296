import React from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getStorage, ref } from "firebase/storage";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyC8F9ACfCCviMn7Ygzqz_fVcrVimu8owNQ",
  authDomain: "matty-628bf.firebaseapp.com",
  databaseURL: "https://matty-628bf-default-rtdb.firebaseio.com",
  projectId: "matty-628bf",
  storageBucket: "matty-628bf.appspot.com",
  messagingSenderId: "267834796670",
  appId: "1:267834796670:web:12678102b14b29b07b862f",
  measurementId: "G-V0QYX0NJBB"
};
const firebase = initializeApp(firebaseConfig);

const auth = getAuth(firebase);
const storage = getStorage();
const db = getFirestore(firebase)
export {firebase, auth, storage, db, ref};
