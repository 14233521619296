import './App.css';
import React, { useEffect, useState } from "react";
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import AppRouter from './router'
import { store, persistor  } from "./store";
import MainLayout from "./components/Layouts/MainLayout";
import Loading from "./components/Loading";

export default function () {
  console.log('start')
  const [isLoading, setLoading] = useState(false)

  const fakeRequest = () => {
    return new Promise(resolve => setTimeout(() => resolve(true), 1500))
  }

  const hideLoader = async () => {
    await fakeRequest()
    const el = document.querySelector('.loader')
    if (el) {
      el.remove()
      setLoading(false)
    }
  }
  useEffect(() => {
    hideLoader()
  }, [])

  if (isLoading) {
    return null
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} onBeforeLift={undefined} persistor={persistor}>
        <React.Fragment>
          <AppRouter>{(content: any, routeProps: any) => <MainLayout {...routeProps}>{content}</MainLayout>}</AppRouter>
          <Loading/>
        </React.Fragment>
      </PersistGate>
    </Provider>
  )
}
