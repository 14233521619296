import { IState } from './verify.types'
import IAction from "src/interfaces/IAction"
import * as CONSTANTS from './verify.constants'

// @ts-ignore
export const initialState: IState = {
  loading: false
}

export default (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case CONSTANTS.START_LOADING:
      return { ...state, loading: true }
    case CONSTANTS.STOP_LOADING:
      return { ...state, loading: false }
    default:
      return state
  }
}
