export default {
  ROOT: '/',
  NOT_FOUND: '404',
  SERVER_ERROR: '500',
  SIGN_IN: '/login',
  SIGN_UP: '/signup',
  RESET_PASSWORD: '/reset-password',
  RESTORE_PASSWORD: '/restore-password/:token',
  UPDATE_PASSWORD: '/update-password',

  BOOKING: '/employees'
}
