// @ts-nocheck
import React, { ReactElement, useEffect, useState } from 'react'
import {CheckCircleOutlined} from '@ant-design/icons'
import Background from './../../../assets/overlay.png';

import * as faceapi from 'face-api.js';

import { Button, Card, Col, Form, Row, Skeleton } from "antd";
import { useWindowDimensions, getDeviceCategory } from "../../../utils/useWindowDimensions";
import { useReduxDispatch } from "../../../helpers";
import { checkRequest, processRequest, saveExpressionData } from "../verify.actions";
import { openNotification } from "../../../utils/notifications";
import Header from "../../../components/Header";

const scaleW = {
  xs: 0.75,
  sm:0.8,
  md: 0.6,
  lg: 0.5
}
const scaleY = {
  xs: 0.8,
  sm:1,
  md: 0.6,
  lg: 0.67
}

export default function VerifyContainer(): ReactElement {
  const dispatch = useReduxDispatch();
  const [modelsLoaded, setModelsLoaded] = React.useState(false);
  const [captureVideo, setCaptureVideo] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [done, setDone] = React.useState(false);

  const videoRef = React.useRef();
  const canvasRef = React.useRef();
  const faceRef = React.useRef();
  const smileRef = React.useRef();
  const doneRef = React.useRef();
  const expressionRef = React.useRef();
  const capturedFaceRef = React.useRef();

  const selfie = React.useRef(true);
  const takeSelfie = React.useRef(false);
  const liveness = React.useRef(false);

  const { width, height } = useWindowDimensions();
  console.log('window', width, height)
  const videoHeight = height * scaleY[getDeviceCategory(width)];
  const videoWidth = width * scaleW[getDeviceCategory(width)];

  const isMobile = getDeviceCategory(width) === 'xs';

  const requestId = window.location.search.split('requestid=')[1]

  const options = new faceapi.SsdMobilenetv1Options({ minConfidence: 0.7 })

  const loadModels = async () => {
    const MODEL_URL = process.env.PUBLIC_URL + '/weights';

    Promise.all([
      faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL),
      faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
      faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
      faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
    ]).then(() => {
      setModelsLoaded(true)
      //setCaptureVideo(true)
      startVideo()
    });
  }

  useEffect(()=>{
    const requestId = window.location.search.split('requestid=')[1]
    //@ts-ignore
    dispatch(checkRequest(requestId)).then((data: any)=>{
      if(data?.requestClosed){
        openNotification('error', 'Invalid Request', 'This link is invalid or expired.')
      }
      else {
        loadModels();
      }
    })
  },[])

  const startVideo = () => {
    setCaptureVideo(true);
    navigator.mediaDevices
      .getUserMedia({
        video: {
          width: { ideal: 4096 },
          height: { ideal: 2160 },
          facingMode: { ideal: "user" }
        },
        audio: false,
      })
      .then(stream => {
          setLoading(false)
          let video = videoRef.current;
          video.srcObject = stream;
        video.onloadedmetadata = function(e) {
          video.play();
        };
      })
      .catch(err => {
        openNotification('error', 'No Webcam', err)
        console.error("error:", err);
      });
  }

  const handleVideoOnPlay = () => {
    setInterval(async () => {
      console.log('current', selfie.current, takeSelfie.current)
      if(selfie.current && takeSelfie.current) {
        document.getElementById("results").textContent = "Capturing Photo, Please don't move.";
        if (canvasRef && canvasRef.current) {
          canvasRef.current.innerHTML = await faceapi.createCanvasFromMedia(videoRef.current);
          const result = await faceapi.detectSingleFace(videoRef.current, options)
          console.log('result',result)
          if (result) {
            const box = result.box
            let faceImages = await faceapi.extractFaces(videoRef.current, [
              new faceapi.Rect(box.x - 100, box.y-100, box.width+200, box.height+200)
            ])
            if (faceImages && faceImages.length > 0) {
              capturedFaceRef.current = faceImages[0].toDataURL()
              selfie.current = false
              document.getElementById('liveness-label').textContent = 'LIVENESS DETECTION';
              document.getElementById("results").textContent = "Get ready for liveness detection.";
              document.getElementById('go-button').style.display = 'block';
            }
          }
        }
        return;
      }
      if (liveness.current && canvasRef && canvasRef.current) {
        canvasRef.current.innerHTML = await faceapi.createCanvasFromMedia(videoRef.current);
        const result = await faceapi.detectSingleFace(videoRef.current, options).withFaceLandmarks().withFaceExpressions()
        if (result) {
          const expression = result.expressions.asSortedArray()[0];
          const displaySize = {
            width: videoWidth,
            height: videoHeight
          }

          const dims = faceapi.matchDimensions(canvasRef.current, videoRef.current, true);


          const resizedDetections = faceapi.resizeResults(result, dims)

          //canvasRef && canvasRef.current && canvasRef.current.getContext('2d').clearRect(0, 0, videoWidth, videoHeight);
          canvasRef && canvasRef.current && faceapi.draw.drawDetections(canvasRef.current, resizedDetections);
          canvasRef && canvasRef.current && faceapi.draw.drawFaceExpressions(canvasRef.current, resizedDetections);

          if (!smileRef.current) {
            if (expression.expression === 'happy' && expression.probability > 0.75) {
              smileRef.current = true
              doneRef.current = true
              document.getElementById("results").textContent = "Saving data, please wait... "
              extractFaceFromBox(requestId)
            } else
              document.getElementById("results").textContent = "Please smile."
          }
        }else{
          if (!doneRef.current) {
            expressionRef.current = false
            smileRef.current = false
            document.getElementById("results").textContent = "No Face detected. Please make sure your full face is visible";
          }
        }
      }
    }, 500)
  }

  const closeWebcam = () => {
    videoRef.current.pause();
    videoRef.current.srcObject.getTracks()[0].stop();
    setCaptureVideo(false);
    setDone(true)
  }

  const extractFaceFromBox = async (id) =>{
    dispatch(saveExpressionData({
      id,
      imageData: capturedFaceRef.current
    })).then((data)=>{
      if(data){
        document.getElementById("results").textContent = "We have received your info. You can close the window.";
        document.getElementById("success-block").style.display = 'inline-flex';
        closeWebcam()
        dispatch(processRequest(id))
      }
    })
  }

  const SelfiView = ()=>{
    return(
      <Card className={'verify-card'}>
        {!loading && !done && <Col xs={24}>
          <h3 id={'results'} style={{ textAlign: 'center' }}>Get Ready for selfie</h3>
        </Col>}
        <Col xs={24}>
          {
            captureVideo && modelsLoaded &&
            <div style={{ width: '100%', height: '68vh', overflow:'hidden' }}>
              <video muted autoPlay playsInline ref={videoRef} height={videoHeight} width={width} onPlay={handleVideoOnPlay}
                     style={{ borderRadius: '10px' }}/>
              <canvas
                id={'canvas'}
                ref={canvasRef}
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  backgroundImage: isMobile ? "url(" + Background + ")" : '',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  left:0,
                  right:0,
                  margin:'auto',
                }}>
              </canvas>
            </div>
          }
          {loading && <Skeleton.Button block={true} active={true} style={{height: 400}}/>}
        </Col>
        <Row align={'middle'} justify={'center'}>
          <Col xs={18} md={12}>
            <Button
              onClick={()=>takeSelfie.current = true }
              disabled={takeSelfie.current}
              block
              className={'accept-button'}>
              Let's Go
            </Button>
          </Col>
        </Row>
      </Card>
    )
  }

  const LivenesView = ()=>{
    return(
      <Card className={'verify-card'}>
        {!loading && !done && <Col xs={24} className={'info-box'}>
          <h2 id={'liveness-label'} style={{ textAlign: 'center', marginBottom: 0 }}>SELFI CAPTURE</h2>
          <h5 id={'results'} style={{ textAlign: 'center' }}>Get Ready for selfie</h5>
        </Col>}
        <Col xs={24}>
          {
            captureVideo && modelsLoaded &&
            <div style={{ width: '100%', height: '69vh' }}>
              <video muted autoPlay playsInline ref={videoRef} onPlay={handleVideoOnPlay}
                     style={{ borderRadius: '10px' }}/>
              {/*<canvas
                id={'canvas'}
                ref={canvasRef}
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  backgroundImage: isMobile ? "url(" + Background + ")" : '',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  left:0,
                  right:0,
                  margin:'auto',
                }}>
              </canvas>*/}
      </div>
          }
          {loading && <Skeleton.Button block={true} active={true} style={{height: 400}}/>}
        </Col>
        {selfie.current && <Row align={'middle'} justify={'center'}>
          <Col xs={18} md={12}>
            <Button
              id={'go-button'}
              style={{marginTop: -40}}
              onClick={()=> {
                document.getElementById('go-button').style.display = 'none';
                if(!takeSelfie.current) {
                  takeSelfie.current = true;
                  document.getElementById("results").textContent = "Capturing Photo, Please don't move.";
                }
                else {
                  document.getElementById("results").textContent = "Detecting face...";
                  liveness.current = true
                }
              }}
              disabled={takeSelfie.current}
              block
              className={'accept-button'}>
              Let's Go
            </Button>
          </Col>
        </Row>}
      </Card>
    )
  }

  return (
    <div className={'app-container p-20'} style={{marginTop: 0}}>
      <Header/>
      <Row align={'middle'} justify={'center'}>
        <Col xs={24} md={16}>
          <Card className={'verify-card'}>
            {!loading && !done && <Col xs={24} className={'info-box'}>
              <h2 id={'liveness-label'} style={{ textAlign: 'center', marginBottom: 0 }}>SELFI CAPTURE</h2>
              <h5 id={'results'} style={{ textAlign: 'center' }}>Get Ready for selfie</h5>
            </Col>}
            <Col xs={24}>
              {
                captureVideo && modelsLoaded &&
                <div style={{ width: '100%', height: '69vh', alignItems:'center',  display:'flex' }}>
                  <video width={'100%'} height={videoHeight} muted autoPlay playsInline ref={videoRef} onPlay={handleVideoOnPlay}
                         style={{ borderRadius: '10px' }}/>
                  <canvas
                id={'canvas'}
                ref={canvasRef}
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  //backgroundImage: isMobile ? "url(" + Background + ")" : '',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  left:0,
                  right:0,
                  margin:'auto',
                }}>
              </canvas>
                </div>
              }
              {loading && <Skeleton.Button block={true} active={true} style={{height: 400}}/>}
            </Col>
            {selfie.current && <Row align={'middle'} justify={'center'}>
              <Col xs={18} md={12}>
                <Button
                  id={'go-button'}
                  style={{marginTop: -40}}
                  onClick={()=> {
                    document.getElementById('go-button').style.display = 'none';
                    if(!takeSelfie.current) {
                      takeSelfie.current = true;
                      document.getElementById("results").textContent = "Capturing Photo, Please don't move.";
                    }
                    else {
                      document.getElementById("results").textContent = "Detecting face...";
                      liveness.current = true
                    }
                  }}
                  disabled={takeSelfie.current}
                  block
                  className={'accept-button'}>
                  Let's Go
                </Button>
              </Col>
            </Row>}
          </Card>
        </Col>
      </Row>
      <div
        id={'success-block'}
        className={'success-block'}
        style={{
          position:'fixed',
          display: 'none',
        }}
      >
        <div className={'thank-you'}>Thank You</div>
        <div className={'thank-you thank-you-message'}>Received your information, you can close the window.</div>
      </div>
    </div>
  )
}
