import { IState, IServerError } from './core.types'
import IAction from "src/interfaces/IAction"
import * as CONSTANTS from './core.constants'
import get from 'lodash/get'

export const initialState: IState = {
  isAuthenticated: false,
  showProgress: false,
  progress: 0,
  isLoggingOut: false,
  isLoggingIn: false,
  isUploading: false,
  isDownloading: false,
  settingsLoading: false,
  constantsLoading: false,
  fileUrls: [],
  uploadError: '',
  downloadError: '',
  fetchCurrenciesError: '',
  serverError: null,
  currentUser: {
    id: null,
    firstName: '',
    lastName: '',
    companyName: '',
    companyWebsite: '',
    phone: '',
    email: '',
    role: '',
    roleId: null,
    status: '',
    isBanned: '',
    profilePicture: '',
    isApproved: false,
    unreadNotificationCount: 0,
  },
}

export default (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case CONSTANTS.RESET_PROGRESS:
      return { ...state, showProgress: initialState.showProgress, progress: initialState.progress }
    case CONSTANTS.SET_PROGRESS:
      return { ...state, showProgress: true, progress: get(action, 'payload.progress') }

    case CONSTANTS.LOGOUT_REQUEST:
      return { ...state, isLoggingOut: true }
    case CONSTANTS.LOGOUT_SUCCESS:
      return initialState
    case CONSTANTS.LOGOUT_FAILURE:
      return { ...state, isLoggingOut: false }

    case CONSTANTS.SET_SERVER_ERROR:
      return { ...state, serverError: action.payload as IServerError }
    case CONSTANTS.RESET_SERVER_ERROR:
      return { ...state, serverError: initialState.serverError }

    case CONSTANTS.UPLOAD_REQUEST:
      return { ...state, isUploading: true }
    case CONSTANTS.UPLOAD_SUCCESS:
      return { ...state, isUploading: false, fileUrls: [...state.fileUrls, ...get(action, 'payload.fileUrls')] }
    case CONSTANTS.UPLOAD_FAILURE:
      return { ...state, isUploading: false, uploadError: action.error }

    case CONSTANTS.DOWNLOAD_REQUEST:
      return { ...state, isDownloading: true }
    case CONSTANTS.DOWNLOAD_SUCCESS:
      return { ...state, isDownloading: false }
    case CONSTANTS.DOWNLOAD_FAILURE:
      return { ...state, isDownloading: false, downloadError: action.error }
    default:
      return state  
  }
}