//@ts-nocheck
import React, { useMemo } from 'react'
import { Router, Route,  BrowserRouter, Switch, Routes } from 'react-router-dom'
import { GuardProvider, GuardedRoute } from 'react-router-guards'
import getRoutes from './routes'
import NotFound from '../modules/core/containers/NotFound'
import requireLogin from './guards/requireLogin'
import history from '../utils/history'
import requirePermission from './guards/requirePermission'
import { VerifyContainer, TosContainer, UploadContainer } from '../modules/verify'

const GLOBAL_GUARDS = [requireLogin, requirePermission]

interface Props {
  children: any
}

const AppRouter = ({ children }: Props) => {
  const routes = useMemo(() => getRoutes(), [])
  console.log('routes', routes)
  return (
    <BrowserRouter>
      <Routes>
        <Route key={'verify'} path={'/verify'} element={<TosContainer/>}/>
        <Route key={'upload'} path={'/upload'} element={<UploadContainer/>}/>
        <Route key={'face_detection'} path={'/face_detection'} element={<VerifyContainer/>}/>
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
