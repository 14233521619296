import React from "react";
import ClipLoader from "react-spinners/BounceLoader";
import { useSelector } from "react-redux";
import { getLoading } from "../../modules/verify/verify.selectors";

function Loading() {
  const isLoading = useSelector(getLoading)
  if(!isLoading)
    return null
  return (
    <div style={{
      position: 'fixed',
      inset: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 100000
    }}>
      <ClipLoader color={'#1e40af'} loading={true} size={120}/>
    </div>
  )
}

export default Loading
