import { VerifyContainer, TosContainer } from '../modules/verify'
import { PATHS } from "../constants";

export default () => [
  {
    path: PATHS.ROOT,
    exact: true,
    component: TosContainer,
    loading: 'Custom loading for home page...',
    error: 'Custom error for home page',
  },
  {
    path: '*',
    component: TosContainer,
    ignoreGlobal: true,
  },
]
